import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { getCompanyPDFLocalStorage } from '../api/insuranceLocalStorage';
import CompanyPDF2 from '../components/CompanyPDF/CompanyPDF2';
import { IconContext } from "react-icons";
import { HiDownload } from "react-icons/hi";

const ConvertPDF = () => {
  const pdfData = getCompanyPDFLocalStorage();
  const pdfData2 = {
    response: {
      trucks: pdfData.trucks,
      drivers: pdfData.drivers,
      legalName: pdfData.company.companyName,
      address: pdfData.company.companyAddres,
      city: pdfData.company.companyCity,
      garageState: pdfData.company.garageState,
      garageZip: pdfData.company.garageZip,
    },
    effectiveDate: pdfData.startDate,
    producer: pdfData.producer,
    insuranceData: pdfData.insuranceData
  }
  const companyName = pdfData.company.companyName ? pdfData.company.companyName : '';
  const submissionNmber = pdfData.company.submissionNmber ? pdfData.company.submissionNmber : '';
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = today.getMonth();
  const yyyy = today.getFullYear();
  const monthName = month[mm];
  const todayTitle = monthName + ' ' + dd + ', ' + yyyy;

  return (
    <div style={{position:"relative"}}>
      <div className="loader">
        <h1 className="generatePDFTitle">GENERATING PDF . . .</h1>
        <div className="spinner" />
      </div>
      <PDFViewer style={{width:"100%", height:"100vh", position:"absolute", left:"0", top:"0", marginTop: "-64px", zIndex: "101"}}>
        <CompanyPDF2 pdfData={pdfData2} />
      </PDFViewer>
      <PDFDownloadLink
        className="hidden xl:block fixed w-fit mr-24 mt-2 right-0 top-0 z-[102] text-white bg-[rgb(50,54,57)]"
        document={<CompanyPDF2 pdfData={pdfData2} />}
        fileName={`${companyName}-${todayTitle}-${submissionNmber}.pdf`}
      >
        {({ loading }) => (loading ? '' :
          <div className="p-2">
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <HiDownload />
            </IconContext.Provider>
          </div>
        )}
      </PDFDownloadLink>
    </div>
  );
};

export default ConvertPDF;
