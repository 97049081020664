import { NavLink } from 'react-router-dom';
import { getCompanyPDFLocalStorage } from '../api/insuranceLocalStorage';

const ComercialInsurance = () => {
  const data = getCompanyPDFLocalStorage();
  const insuranceData = data.insuranceData;
  const premium = insuranceData.premium || 0;
  const al = insuranceData.al.premium || 0;
  const apd = insuranceData.apd.premium || 0;
  const mtc = insuranceData.mtc.premium || 0;
  const tgl = insuranceData.tgl.premium || 0;
  const ntl = insuranceData.ntl.premium || 0;
  const submissionNmber = insuranceData.submissionNmber || '';
  const coverwhalePage = `https://app.coverwhale.com/transportation/${submissionNmber}/0`;
  const company = data.company;
  const trucks = data.trucks;

  const numberWithCommas = (x) => {
    return x.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div className="flex flex-col items-center mb-40 -mt-20">
      <a
        className="bg-purple-700 text-white text-3xl w-3/4 rounded p-2 mt-10 mb-4"
        href="/pdf"
        target="_blank"
        rel='noreferrer'
      >
        <b>Generate PDF Quote</b>
      </a>
      <h2 className="text-4xl"><b>Commercial Truck Insurance for:</b></h2>
      <h2 className="text-4xl"><b>{company.companyName}</b></h2>
      <h3 className="text-2xl my-4">Price Indication Summary</h3>
      <div className="bg-neutral-200 rounded w-2/3 px-10 py-12">
        <p className="text-2xl"><b>ESTIMATED ANNUAL PREMIUM</b></p>
        <p>
          <b className="text-green-700 text-5xl xl:text-9xl">${numberWithCommas(premium/trucks)}</b>
          <b className="text-5xl">/truck</b>
        </p>
      </div>
      <div className="grid grid-cols-4 mt-6 mb-8 w-3/4 border-4 border-black">
        <div className="col-span-4 bg-blue-900 text-white p-4 flex flex-row justify-between border-b-4 border-black">
          <b className="w-fit">Coverage</b>
          <b className="w-fit">Total Cost</b>
        </div>
        {al !== 0 &&
          <div className="col-span-4 flex flex-row justify-between odd:bg-slate-200">
            <b className="w-3/4 text-left p-4">Automobile Liability</b>
            <b className="w-1/4 text-right p-4 border-l-4 border-black">${numberWithCommas(al)}</b>
          </div>
        }
        {apd !== 0 &&
          <div className="col-span-4 flex flex-row justify-between odd:bg-slate-200">
            <b className="w-3/4 text-left p-4">Automobile Physical Damage</b>
            <b className="w-1/4 text-right p-4 border-l-4 border-black">${numberWithCommas(apd)}</b>
          </div>
        }
        {mtc !== 0 &&
          <div className="col-span-4 flex flex-row justify-between odd:bg-slate-200">
            <b className="w-3/4 text-left p-4">Motor Truck Cargo</b>
            <b className="w-1/4 text-right p-4 border-l-4 border-black">${numberWithCommas(mtc)}</b>
          </div>
        }
        {tgl !== 0 &&
          <div className="col-span-4 flex flex-row justify-between odd:bg-slate-200">
            <b className="w-3/4 text-left p-4">Truckers General Liability</b>
            <b className="w-1/4 text-right p-4 border-l-4 border-black">${numberWithCommas(tgl)}</b>
          </div>
        }
        {ntl !== 0 &&
          <div className="col-span-4 flex flex-row justify-between odd:bg-slate-200">
            <b className="w-3/4 text-left p-4">Non-Trucking Liability</b>
            <b className="w-1/4 text-right p-4 border-l-4 border-black">${numberWithCommas(ntl)}</b>
          </div>
        }
        <div className="col-span-4 flex flex-row justify-between odd:bg-slate-200 border-t-4 border-black">
          <p className="w-3/4 text-left p-4"><b>Total Cost </b>(Taxes and Fees not included)</p>
          <b className="w-1/4 text-right p-4 border-l-4 border-black">${numberWithCommas(premium)}</b>
        </div>
      </div>
      <div className="mt-10 mb-28 w-3/4 flex justify-between">
        <NavLink
          to={'/newinsurance'}
          className="bg-red-700 text-white w-5/12 rounded p-4"
          >
          <b>&larr; GO BACK TO NEW INSURANCE</b>
        </NavLink>
        <a
          className="bg-green-700 text-white w-5/12 rounded p-4"
          href={coverwhalePage}
          target="_blank"
          rel="noopener noreferrer"
          >
          <b>CONTINUE TO QUOTE &rarr;</b>
        </a>
      </div>
    </div>
  );
};

export default ComercialInsurance;
