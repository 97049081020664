import React from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { postCSVAxios1 } from "../../api/axiosFunctions";
import './styles/index.css';

const Importercsv1 = () => {
  //posting the csv file to api, for data parsing

  return (
    <div className="importercsv_div mt-2">
      <h1>React CSV Importer sandbox 1</h1>

      <div className="w-1/2">

        <Importer
          chunkSize={10000} // optional, internal parsing chunk size in bytes
          restartable={false} // optional, lets user choose to upload another file when import is complete
          dataHandler={async (rows, { startIndex }) => {
            // required, may be called several times
            // receives a list of parsed objects based on defined fields and user column mapping;
            // (if this callback returns a promise, the widget will wait for it before parsing more data)
            // for (row of rows) {
            //   await myAppMethod(row);
            // }
            console.log(rows, startIndex);
            await new Promise((resolve) => setTimeout(resolve, 4000));
          }}
          onStart={async ({ file, fields }) => {
            // optional, invoked when user has mapped columns and started import
            console.log("starting import of file", file, "with fields", fields);
            await postCSVAxios1(file);
          }}
          onComplete={({ file, fields }) => {
            // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
            console.log("finished import of file", file, "with fields", fields);
          }}
          onClose={() => {
            // optional, invoked when import is done and user clicked "Finish"
            // (if this is not specified, the widget lets the user upload another file)
            console.log("importer dismissed");
          }}
        >
          <ImporterField name="firstName" label="Owner First Name" />
          <ImporterField name="lastName" label="Owner Last Name" />
          <ImporterField name="email" label="Owner Email" />
          <ImporterField name="phone" label="Owner Phone" />
          <ImporterField name="dotNumber" label="DOT Number" />
          <ImporterField name="businessYears" label="Years In Business" />
          <ImporterField name="garageState" label="Garage State" />
          <ImporterField name="garageZip" label="Garage Zip" />
          <ImporterField name="drivers" label="Number of Drivers" />
          <ImporterField name="trucks" label="Number of Trucks" />
          <ImporterField name="avgValueTruck" label="Average Value Per Truck" />
          <ImporterField name="trailers" label="Number of Trailers" />
          <ImporterField name="autoLiability" label="Auto Liability (AL)" />
          <ImporterField name="personalInjuryProtection" label="Personal Injury Protection (PIP)" />
          <ImporterField name="Uninsured" label="Uninsured / Underinsured" />
          <ImporterField name="physicalDamage" label="Physical Damage (PD)" />
          <ImporterField name="motorTruckCargo" label="Motor Truck Cargo (MTC)" />
        </Importer>
      </div>
    </div>
  );
}

export default Importercsv1;
