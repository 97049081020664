import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFromSubmissionNumber } from "../api/axiosFunctions";

const SubmissionNumberPDF = () => {
  const navigate = useNavigate();
  const [submissionNumber,setsubmissionNumber] = useState(0);

  const handleChange = (event) => {
    let newValue = event.target.value;
    setsubmissionNumber(newValue);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await getFromSubmissionNumber(submissionNumber);
    if (response.status === 404) {
      alert('submission not found');
    } else {
      navigate('/submissionpdf',{state: response.data[0]});
    }
  }

  return (
    <form onSubmit={handleSubmit} className="w-1/2 mt-10">
      <label>Submission Number
        <input name="submissionNumber" type="number" className="stepsinput" onChange={handleChange} />
      </label>
      <div className="flex justify-between">
        <a href="/newinsurance" className="bg-blue-700 text-white w-fit rounded-3xl py-2 px-10">Return to Create Insurance Form</a>
        <button type="submit" className="bg-red-700 text-white w-fit rounded-3xl py-2 px-10">Create PDF with Submission Number</button>
      </div>
    </form>
  );
};

export default SubmissionNumberPDF;
