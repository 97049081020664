import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { BiLinkExternal } from 'react-icons/bi';

const NotFound = () => (
  <div className=' flex justify-center'>
    <section className='font-bold mt-20 bg-slate-300 w-fit rounded-3xl p-10'>
      <h1 className='text-4xl text-red-700'>404 Not Found</h1>
      <p className="my-10 text-lg">Oops, the page you're looking for doesn't exist.</p>
      <div className="flex justify-center gap-x-8 whitespace-nowrap">
        <Link
          className='bg-blue-800 text-white w-fit rounded-xl py-2 px-6 flex items-center gap-x-2'
          to='/newinsurance'
        >
          <p>Go to home</p>
          <IconContext.Provider value={{ size: "20" }}>
            <BiLinkExternal />
          </IconContext.Provider>
        </Link>
      </div>
    </section>
  </div>
);

export default NotFound;
