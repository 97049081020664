import React from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { postCSVCarrierSoftwareAxios } from "../../api/axiosFunctions";
import './styles/index.css';

const ImportercsvCarrierSoftware = ({upploadLink}) => (
  <div className="importercsv_div mt-8">
    <h1>Carrier Software to {upploadLink}</h1>

    <div className="w-1/2">
      <Importer
        dataHandler={async () => {
          // required, may be called several times
          // receives a list of parsed objects based on defined fields and user column mapping;
          // (if this callback returns a promise, the widget will wait for it before parsing more data)
          await new Promise((resolve) => setTimeout(resolve, 4000));
        }}
        onStart={async ({ file }) => {
        // optional, invoked when user has mapped columns and started import
        await postCSVCarrierSoftwareAxios(file, upploadLink);
        }}
      >
        <ImporterField name="DOT" label="DOT" />
        <ImporterField name="DocketNo" label="Docket No" />
        <ImporterField name="LegalName" label="Legal Name" />
        <ImporterField name="DBAName" label="DBA Name" />
        <ImporterField name="CompanyRep1" label="Company Rep1" />
        <ImporterField name="Phone" label="Phone" />
        <ImporterField name="Cell Phone" label="Cell Phone" />
        <ImporterField name="EmailAddress" label="Email Address" />
        <ImporterField name="Address" label="Address" />
        <ImporterField name="City" label="City" />
        <ImporterField name="State" label="State" />
        <ImporterField name="Zip" label="Zip" />
        <ImporterField name="PolicyDate" label="Policy Date" />
        <ImporterField name="Day" label="Day" />
        <ImporterField name="InsCarrier" label="Ins. Carrier" />
        <ImporterField name="PolicyNo" label="Policy No." />
        <ImporterField name="BiPDFile" label="BiPD on File" />
        <ImporterField name="NoPU" label="No. PU" />
        <ImporterField name="NumberDrivers" label="No. Drivers" />
        <ImporterField name="ClassCodes" label="Class Codes" />
        <ImporterField name="CargoCode" label="Cargo Codes" />
        <ImporterField name="CargoOther" label="Cargo Other" />
        <ImporterField name="RegDate" label="Reg. Date" />
      </Importer>
    </div>
  </div>
);


export default ImportercsvCarrierSoftware;
