import { Document, Page, View, Text, Image } from '@react-pdf/renderer';
import CoverWhaleLogo from '../../images/CoverWhaleLogoPDF.png';
import truck from '../../images/Quote-Truck-Image.png';
import logo from '../../images/AFI-FullColorPDF.jpg';

const CompanyPDF = ({pdfData}) => {
  const premium = pdfData.insuranceData.premium || 0;
  const al = pdfData.insuranceData.al.premium || 0;
  const apd = pdfData.insuranceData.apd.premium || 0;
  const mtc = pdfData.insuranceData.mtc.premium || 0;
  const tgl = pdfData.insuranceData.tgl.premium || 0;
  const ntl = pdfData.insuranceData.ntl.premium || 0;
  const submissionNmber = pdfData.insuranceData.submissionNmber || '';
  const producerName = pdfData.producer.AgentName || 'No name';
  const producerMail = pdfData.producer.EmailAddress || 'No mail';
  const producerPhone = pdfData.producer.WorkPhone || 'No phone';
  const company = pdfData.company;
  const companyName = company.companyName.toUpperCase();
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const changeDate = (date) => {
    let policyDate = date.split("-");
    const dd = policyDate[2];
    const mm = parseInt(policyDate[1]) - 1;
    const yyyy = policyDate[0];
    const monthName = month[mm];
    policyDate = monthName + ' ' + dd + ', ' + yyyy;
    return policyDate;
  };
  const startDate = changeDate(pdfData.startDate);
  let nextYear = pdfData.startDate.split('-');
  nextYear[0] = parseInt(nextYear[0]) + 1;
  const finishDate = changeDate(nextYear.join("-"));

  const today = new Date();
  let todayWithoutGMT= today.toString().split(' ').slice(4);
  todayWithoutGMT.splice(1,1);
  const todayString = todayWithoutGMT.join(' ');
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = today.getMonth();
  const yyyy = today.getFullYear();
  const monthName = month[mm];
  const todayTitle = monthName + ' ' + dd + ', ' + yyyy;

  const numberWithCommas = (x) => {
    return x.toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Document title={`${companyName}-${todayTitle}-${submissionNmber}`}>
      <Page size="A4">
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh"
          }}
        >
          <View
            style={{
              width: "85%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "20px"
            }}
          >
            <Image src={CoverWhaleLogo} alt="CoverWhale logo" style={{width: "20%"}} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: '8px',
                textAlign: 'rigth',
                color: "rgb(156, 156, 156)",
                fontFamily: 'Helvetica-Bold'
              }}
            >
              <Text style={{alignSelf: 'flex-end'}}>QUOTE #{submissionNmber}</Text>
              <Text style={{ alignSelf: 'flex-end'}}>{companyName}</Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "16px",
              fontSize: '24px',
              fontFamily: "Helvetica-Bold"
            }}
          >
            <Text>Commercial Trucking application & Quote for:</Text>
            <Text style={{ fontSize: "18px", marginTop: "12px"}}>{companyName}</Text>
            <View
              style={{
                marginTop: "16px",
                marginHorizontal: "32px",
                fontSize: '13px',
                textAlign: 'center',
                color: 'rgb(126 34 206)',
                fontFamily: 'Times-Roman'
              }}
            >
              <Text>Quote can be bound if all application information in this document is correct. If any discrepancies are</Text>
              <Text>found or any conditions of coverage are not met, your policy may be subject to automatic price change,</Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center"
                }}
              >
                <Text>non-renewal, or cancellation.</Text>
                <Text style={{fontFamily: 'Times-Bold'}}> Quote is valid for 30 days</Text>
              </View>
            </View>
          </View>
          <Image src={truck} alt="truck" style={{marginTop: "12px", width: '90%'}} />
          <View
            style={{
              width: "90%",
              border: '1px solid rgb(6, 50, 120)',
              marginTop: "16px",
              marginBottom: "20px",
              fontSize: '12px'
            }}
          >
            <View style={{height: "12px", backgroundColor: "rgb(6, 50, 120)"}} />
            <View
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: 'row'
              }}
            >
              <Text style={{width: "30%", fontFamily: 'Helvetica-Bold'}}>Insured Name:</Text>
              <Text style={{width: "70%"}}>{companyName}</Text>
            </View>
            <View
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center",
                backgroundColor: 'rgb(242, 242, 242)'
              }}
            >
              <Text style={{width: "30%", fontFamily: 'Helvetica-Bold'}}>Address:</Text>
              <View style={{width: "70%"}}>
                <Text>{company.companyAddres}</Text>
                <Text>{company.companyCity}, {company.garageState} {company.garageZip}</Text>
              </View>
            </View>
            <View
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: 'row'
              }}
            >
              <Text style={{width: "30%", fontFamily: 'Helvetica-Bold'}}>Policy Term:</Text>
              <View
                style={{
                  width: "70%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start"
                }}
              >
                <View
                  style={{
                    width: "70%",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <Text>From:</Text>
                  <Text style={{textDecoration: "underline", marginLeft: "6px"}}>{startDate}</Text>
                </View>
                <View
                  style={{
                    width: "70%",
                    display: "flex",
                    flexDirection: "row"
                  }}
                >
                  <Text>To:</Text>
                  <Text style={{textDecoration: "underline", marginLeft: "6px"}}>{finishDate}</Text>
                </View>
              </View>
            </View>
            <View
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center",
                backgroundColor: 'rgb(242, 242, 242)'
              }}
            >
              <Text style={{width: "30%", fontFamily: 'Helvetica-Bold'}}>Broker Contact:</Text>
              <View style={{width: "70%", display: "flex", flexDirection: "column"}}>
                <Text>American Fleet Insurance - {producerName}</Text>
                <Text>({producerMail}) {producerPhone}</Text>
              </View>
            </View>
            <View
              style={{
                padding: "8px",
                display: "flex",
                flexDirection: 'row',
                alignItems: "center"
              }}
            >
              <Text style={{width: "30%", fontFamily: 'Helvetica-Bold'}}>Issued on:</Text>
              <Text style={{width: "70%"}}>{todayTitle} {todayString}</Text>
            </View>
          </View>
          <View
              style={{
                width:"90%",
                padding: "8px",
                display: "flex",
                flexDirection: 'row',
                justifyContent: "center",
                alignItems: "center",
                borderTop: '1px solid black',
                fontSize: "10px",
                marginTop: "auto",
                marginBottom: "12px"
              }}
            >
            <Text>Cover Whale Insurance Solutions, Inc.</Text>
            <Text style={{marginLeft: "12px"}}>Page 1 of 2</Text>
          </View>
        </View>
      </Page>
      <Page size="A4">
        <View 
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh"
          }}
          >
          <View
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "20px"
            }}
          >
            <Image src={CoverWhaleLogo} alt="CoverWhale logo" style={{width: "20%"}} />
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: '8px',
                textAlign: 'rigth',
                color: "rgb(156, 156, 156)",
                fontFamily: 'Helvetica-Bold'
              }}
            >
              <Text style={{alignSelf: 'flex-end'}}>QUOTE #{submissionNmber}</Text>
              <Text style={{alignSelf: 'flex-end'}}>{companyName}</Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10px"
            }}
          >
            <Text style={{fontSize: "24px"}}>Commercial Truck Insurance</Text>
            <Text style={{fontSize: "20px"}}>Price Indication Summary</Text>
            <View
              style={{
                width: "70%",
                borderRadius: "4px",
                backgroundColor: "rgb(229 229 229)",
                padding: "32px 40px",
                margin: "20px 0",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Text style={{fontSize: "16px"}}>TOTAL ESTIMATED ANNUAL PREMIUM</Text>
              <Text style={{color: "rgb(21 128 61)", fontSize: "64px"}}>${numberWithCommas(premium)}</Text>
            </View>
            <View
              style={{
                width: "80%",
                border: '1px solid rgb(6, 50, 120)',
                marginTop: "16px",
                marginBottom: "16px",
                fontSize: '12px'
              }}
            >
              <View
                style={{
                  padding: "16px",
                  display: "flex",
                  flexDirection: 'row',
                  backgroundColor: "rgb(6, 32, 65)",
                  color: "white"
                }}
              >
                <Text style={{width: "75%"}}>Coverage</Text>
                <Text style={{width: "25%", textAlign: "right"}}>Total Cost</Text>
              </View>
              {al !== 0 &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                    borderTop: '1px solid rgb(6, 50, 120)'
                  }}
                >
                  <Text style={{width: "75%", padding: "16px", borderRight: "1px solid rgb(6, 50, 120)"}}>Automobile Liability</Text>
                  <Text style={{width: "25%", padding: "16px", textAlign: "right"}}>${numberWithCommas(al)}</Text>
                </View>
              }
              {apd !== 0 &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                    backgroundColor: "rgb(226 232 240)"
                  }}
                >
                  <Text style={{width: "75%", padding: "16px", borderRight: "1px solid rgb(6, 50, 120)"}}>Automobile Physical Damage</Text>
                  <Text style={{width: "25%", padding: "16px", textAlign: "right"}}>${numberWithCommas(apd)}</Text>
                </View>
              }
              {mtc !== 0 &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                  }}
                >
                  <Text style={{width: "75%", padding: "16px", borderRight: "1px solid rgb(6, 50, 120)"}}>Motor Truck Cargo</Text>
                  <Text style={{width: "25%", padding: "16px", textAlign: "right"}}>${numberWithCommas(mtc)}</Text>
                </View>
              }
              {tgl !== 0 &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                    backgroundColor: "rgb(226 232 240)"
                  }}
                >
                  <Text style={{width: "75%", padding: "16px", borderRight: "1px solid rgb(6, 50, 120)"}}>Truckers General Liability</Text>
                  <Text style={{width: "25%", padding: "16px", textAlign: "right"}}>${numberWithCommas(tgl)}</Text>
                </View>
              }
              {ntl !== 0 &&
                <View
                  style={{
                    display: "flex",
                    flexDirection: 'row',
                  }}
                >
                  <Text style={{width: "75%", padding: "16px", borderRight: "1px solid rgb(6, 50, 120)"}}>Non-Trucking Liability</Text>
                  <Text style={{width: "25%", padding: "16px", textAlign: "right"}}>${numberWithCommas(ntl)}</Text>
                </View>
              }
              <View
                style={{
                  display: "flex",
                  flexDirection: 'row',
                  backgroundColor: "rgb(226 232 240)",
                  borderTop: '1px solid rgb(6, 50, 120)'
                }}
              >
                <Text style={{width: "75%", padding: "16px", borderRight: "1px solid rgb(6, 50, 120)"}}>Total Cost (Taxes and Fees not included)</Text>
                <Text style={{width: "25%", padding: "16px", textAlign: "right"}}>${numberWithCommas(premium)}</Text>
              </View>
            </View>
            <Text style={{marginBottom: "8px"}}>TO FINALIZE QUOTE PLEASE CONTACT:</Text>
            <View
              style={{
                display: "flex",
                flexDirection: 'row'
              }}
            >
              <Image src={logo} alt="AFI logo" style={{width: "20%", height: "36px", marginRight: "24px"}}/>
              <View style={{marginLeft: "24px"}}>
                <Text>{producerName}</Text>
                <Text>{producerPhone}</Text>
                <Text>{producerMail}</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width:"90%",
              padding: "8px",
              display: "flex",
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: "center",
              marginTop: "auto",
              marginBottom: "6px"
            }}
          >
            <View
              style={{
                width:"100%",
                display: "flex",
                flexDirection: 'column',
                justifyContent: "center",
                alignItems: "center",
                marginTop: "auto",
                marginBottom: "12px",
                fontSize: "6px"
              }}
            >
              <Text>*Your actual premium may be higher or lower based on a number of factors. Coverages and discounts are subject to policy terms, conditions, qualifications, and availability.</Text>
              <Text style={{marginTop: "4px"}}>This proposal does not contain all possible coverage options and is only intended to highlight some of your coverage options.</Text>
              <Text>Please contact my agency to further discuss all of the additional coverage options which may be available.</Text>
            </View>
            <View style={{
              width:"100%",
              padding: "8px",
              fontSize: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              borderTop: '1px solid black',
            }}>
              <Text>Cover Whale Insurance Solutions, Inc.</Text>
              <Text style={{marginLeft: "12px"}}>Page 2 of 2</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CompanyPDF;
