import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { getTotalAxios } from "../api/axiosFunctions";
import { getInsuranceLocalStorage } from "../api/insuranceLocalStorage";
import usStates from '../data/us-states-with-quotes.json';

const SelectQuote = () => {
  const [loading, setLoading] = useState(false);
  const [autoLiability, setAutoLiability] = useState('autoLiabilityAccept');
  const [insuranceForm, setInsuranceForm] = useState(getInsuranceLocalStorage());
  const navigate = useNavigate();

  const handleChange = (event) => {
    let newValue = event.target.value;
    const inputName = event.target.name;
    if (inputName === 'avgValueTruck') {
      newValue = newValue.replace(/,/g,'');
      newValue = parseInt(newValue,10);
    };
    setInsuranceForm((prevState)=> {
      return({
        ...prevState,
        [inputName]: newValue,
      });
    });
  };

  const handleChangeAutoLiability = (event) => {
    const newValue = event.target.value;
    const autoLiabilitySelect = document.getElementById('autoLiability');
    if (newValue === 'N') {
      setAutoLiability('autoLiabilityReject');
      autoLiabilitySelect.style.display = "none";
      setInsuranceForm((prevState)=> {
        return({
          ...prevState,
          "autoLiability": newValue,
        });
      });
    }else {
      setAutoLiability('autoLiabilityAccept');
      autoLiabilitySelect.style.display = "block";
      setInsuranceForm((prevState)=> {
        return({
          ...prevState,
          "autoLiability": autoLiabilitySelect.value,
        });
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const insuranceData = await getTotalAxios(insuranceForm);
    setLoading(false);
    insuranceData.premium ? 
      navigate(`/totalquote/?premium=${insuranceData.premium}&&al=${insuranceData.al.premium}&&apd=${insuranceData.apd.premium}&&mtc=${insuranceData.mtc.premium}&&tgl=${insuranceData.tgl.premium}&&submissionNmber=${insuranceData.submissionNmber}`) :
      alert("Ups, something went wrong");
  };

  const restartForm = () => {
    setInsuranceForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dotNumber: 0,
      businessYears: 5,
      garageState: 'AL',
      garageZip: '',
      drivers: 1,
      trucks: 1,
      avgValueTruck: 40000,
      trailers: 1,
      autoLiability: '750000',
      personalInjuryProtection: 'accept',
      uninsured: 'accept',
      physicalDamage: 'accept',
      motorTruckCargo: 'N',
    });
  };

  return (
    <div>
      <h2 className="text-lg mt-4"><b>RAPID QUOTE</b></h2>
      <form className="flex flex-col items-center" onSubmit={handleSubmit}>
        <div className="w-10/12">
          <div className="flex">
            <button type="button" onClick={() => restartForm()} className="w-fit ml-auto text-white bg-blue-800 rounded p-2 text-md">RESET FORM</button>
          </div>
          <div className="grid gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <label>Owner First Name
              <input name="firstName" type="text" onChange={handleChange} value={insuranceForm.firstName} />
            </label>
            <label>Owner Last Name
              <input name="lastName" type="text" onChange={handleChange} value={insuranceForm.lastName} />
            </label>
            <label>Owner Email
              <input name="email" type="email" onChange={handleChange} value={insuranceForm.email} />
            </label>
            <label>Owner Phone
              <input name="phone" type="text" onChange={handleChange} value={insuranceForm.phone} />
            </label>
            <label>DOT Number
              <input name="dotNumber" type="number" onChange={handleChange} value={insuranceForm.dotNumber} />
            </label>
            <label>Years In Business 
              <input name="businessYears" type="number" onChange={handleChange} value={insuranceForm.businessYears} />
            </label>
            <label>Garage State
            <select name="garageState" id="garageState" onChange={handleChange} value={insuranceForm.garageState}>
              {usStates.map((state) => (
                <option key={state.id} value={state.value}>{state.value}</option>
              ))}
            </select>
            </label>
            <label>Garage Zip
              <input name="garageZip" type="text" onChange={handleChange} value={insuranceForm.garageZip} />
            </label>
            <label>Number of Drivers
              <input name="drivers" type="number" onChange={handleChange} value={insuranceForm.drivers} />
            </label>
            <label>Number of Trucks
              <input name="trucks" type="number" onChange={handleChange} value={insuranceForm.trucks} />
            </label>
            <label>Avg. Value Per Truck
              <span className="spanMoneyInput flex items-center">$<NumericFormat value={insuranceForm.avgValueTruck} thousandSeparator="," name="avgValueTruck" onChange={handleChange} /></span>
            </label>
            <label>Number of Trailers
              <input name="trailers" type="number" onChange={handleChange} value={insuranceForm.trailers} />
            </label>
          </div>
        </div>
        <div className="flex flex-wrap justify-center w-11/12 mt-4">
          <label className="w-[14%]">Auto Liability (AL)
            <div className="flex items-center w-5/12 mt-2 ml-8">
              <input
                type="radio"
                value="Y"
                name="autoLiabilityConfirm"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={autoLiability === 'autoLiabilityAccept'}
                onChange={handleChangeAutoLiability}
              />
              Accept
            </div>
            <div className="flex items-center w-5/12 ml-8">
              <input
                type="radio"
                value="N"
                name="autoLiabilityConfirm"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={autoLiability === 'autoLiabilityReject'}
                onChange={handleChangeAutoLiability}
              />
              Reject
            </div>
            <select className={autoLiability} name="autoLiability" id="autoLiability" onChange={handleChange} value={insuranceForm.autoLiability}>
              <option value="750000">$750,000</option>
              <option value="1000000">$1,000,000</option>
            </select>
          </label>
          <label className="w-[14%]">Motor Truck Cargo (MTC)
            <select name="motorTruckCargo" id="motorTruckCargo" onChange={handleChange} value={insuranceForm.motorTruckCargo}>
              <option value="excluded">No, Excluded</option>
              <option value="100000">$100,000</option>
              <option value="125000">$125,000</option>
              <option value="150000">$150,000</option>
              <option value="175000">$175,000</option>
              <option value="200000">$200,000</option>
              <option value="250000">$250,000</option>
            </select>
          </label>
          <label className="w-[19%] ml-4">Personal Injury Protection (PIP)
            <div className="flex items-center w-5/12 ml-8">
              <input
                type="radio"
                value="Y"
                name="personalInjuryProtection"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.personalInjuryProtection === "Y"}
                onChange={handleChange}
              />
              Accept
            </div>
            <div className="flex items-center w-5/12 mt-2 ml-8">
              <input
                type="radio"
                value="N"
                name="personalInjuryProtection"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.personalInjuryProtection === "N"}
                onChange={handleChange}
              />
              Reject
            </div>
          </label>
          <label className="w-1/6">Uninsured / Underinsured
            <div className="flex items-center w-5/12 mt-2 ml-8">
              <input
                type="radio"
                value="Y"
                name="uninsured"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.uninsured === "Y"}
                onChange={handleChange}
              />
              Accept
              </div>
            <div className="flex items-center w-5/12 ml-8">
              <input
                type="radio"
                value="N"
                name="uninsured"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.uninsured === "N"}
                onChange={handleChange}
              />
              Reject
            </div>
          </label>
          <label className="w-[14%]">Physical Damage (PD)
            <div className="flex items-center w-5/12 mt-2 ml-8">
              <input
                type="radio"
                value="Y"
                name="physicalDamage"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.physicalDamage === "Y"}
                onChange={handleChange}
              />
              Accept
            </div>
            <div className="flex items-center w-5/12 ml-8">
              <input
                type="radio"
                value="N"
                name="physicalDamage"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.physicalDamage === "N"}
                onChange={handleChange}
              />
              Reject
            </div>
          </label>
          <label className="w-1/6">Non-Trucking Liability (NTL)
            <div className="flex items-center w-5/12 mt-2 ml-8">
              <input
                type="radio"
                value="Y"
                name="nonTruckingLiability"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.nonTruckingLiability === "Y"}
                onChange={handleChange}
              />
              Accept
              </div>
            <div className="flex items-center w-5/12 ml-8">
              <input
                type="radio"
                value="N"
                name="nonTruckingLiability"
                className="sm:w-1/12 md:w-2/12 mr-8"
                checked={insuranceForm.nonTruckingLiability === "N"}
                onChange={handleChange}
              />
              Reject
            </div>
          </label>
        </div>
        <button type="submit" className="bg-green-700 text-white w-1/2 mt-10 mb-20 rounded p-2"><b>SUBMIT</b></button>
      </form>
      
      { loading && (
        <div className="loader">
          <div className="spinner"> </div>
        </div>
      )}
    </div>
  );
};

export default SelectQuote;
