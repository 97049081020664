import Importercsv1 from '../components/UploadFilesModal/Importercsv1';
import ImportercsvCarrierSoftware from '../components/UploadFilesModal/ImportercsvCarrierSoftware';

const CSVImports = () => (
  <div>
    <Importercsv1 />
    <ImportercsvCarrierSoftware upploadLink="/uploadcarriersoftware" />
    <ImportercsvCarrierSoftware upploadLink="/internalbulk" />
  </div>
);

export default CSVImports;