import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendDotAxios } from "../api/axiosFunctions";
import producers from '../data/AFI-ContactInfo-Producers.json';

const DOTDatePDF = () => {
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;
  const navigate = useNavigate();
  const [DOTNumber, setDOTNumber] = useState(0);
  const [effectiveDate, setEffectiveDate] = useState(today);
  const [producer, setProducer] = useState({
    AgentName: "producer",
    id: "0", 
    EmailAddress: "noemail@teamafi.com",
    WorkPhone: "000000000"
  });

  const handleDOTNumber = (event) => {
    let newValue = event.target.value;
    setDOTNumber(newValue);
  };

  const handleDate = (event) => {
    let newValue = event.target.value;
    setEffectiveDate(newValue);
  };

  const handleProducerChange= (event) => {
    const newValue = event.target.value;
    setProducer(producers.filter((producer) => producer.AgentName === newValue)[0] || {
      AgentName: "producer",
      id: "0", 
      EmailAddress: "noemail@teamafi.com",
      WorkPhone: "000000000"
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await sendDotAxios(DOTNumber);
    if (response.status === 404) {
      alert('submission not found');
    } else {
      navigate('/createpdfdot', {state: {response, effectiveDate, producer, DOTNumber}});
    };
  };

  return (
    <form onSubmit={handleSubmit} className="w-1/2 mt-10">
      <label>DOT Number
        <input name="submissionNumber" type="number" className="stepsinput" onChange={handleDOTNumber} value={DOTNumber} />
      </label>
      <label>Producer
        <select name="producer" className="stepsselect" id="producer" value={producer.AgentName} onChange={handleProducerChange}>
          <option value="producer">Select Producer</option>
          {producers.map((producer) => (
            <option key={producer.id} value={producer.AgentName}>{producer.AgentName}</option>
          ))}
        </select>
      </label>
      <label>Policy Start Date
        <input name="policyStartDate" type="date" className="stepsinput" onChange={handleDate} value={effectiveDate} />
      </label>
      <div className="flex justify-center mt-8">
        <button type="submit" className="bg-red-700 text-white w-1/2 rounded-3xl py-2 px-10">GENERATE PDF</button>
      </div>
    </form>
  );
};

export default DOTDatePDF;
