import { StepsProvider } from "react-step-builder";
import MultiStepsForm from "../components/MultiStepsForm/MultiStepsForm";

const NewComercialInsurance = () => (
  <StepsProvider>
    <MultiStepsForm />
  </StepsProvider>
);

export default NewComercialInsurance;
