import { Route, Routes, useLocation } from 'react-router-dom';
import logo from './images/AFI-FullColor.png';
import SelectQuote from './pages/SelectQuote';
import TotalQuote from './pages/TotalQuote';
import QuoteIndication from './pages/QuoteIndication';
import NewComercialInsurance from './pages/NewComercialInsurance';
import ComercialInsurance from './pages/ComercialInsurance';
import CSVImports from './pages/CSVImports';
import ConvertPDF from './pages/ConvertPDF';
import SubmissionNumberPDF from './pages/SubmissionNumberPDF';
import SubmissionPDF from './pages/SubmissionPDF';
import DotPDF from './pages/DotPDF.js';
import DOTDatePDF from './pages/DOTDatePDF';
import NotFound from './pages/NotFound';
import { getTokenAxios } from './api/axiosFunctions';

function App() {
  const location = useLocation();
  getTokenAxios();
  return (
    <div className="App flex flex-col items-center">
      {!["/insurance", "/pdf", "/submissionpdf", "/createpdfdot"].includes(location.pathname) && 
        <img src={logo} alt="AFI logo" className="logo z-50 w-1/2 -mt-8 lg:w-1/3 xl:w-1/4"/>
      }
      <Routes>
        <Route path="/" element={<SelectQuote />} />
        <Route path="/totalquote" element={<TotalQuote />} />
        <Route path="/quoteindication" element={<QuoteIndication />} />
        <Route path="/newinsurance" element={<NewComercialInsurance />} />
        <Route path="/insurance" element={<ComercialInsurance />} />
        <Route path="/pdf" element={<ConvertPDF />} />
        <Route path="/submissionnumber" element={<SubmissionNumberPDF />} />
        <Route path="/submissionpdf" element={<SubmissionPDF />} />
        <Route path="/getpdfdot" element={<DOTDatePDF />} />
        <Route path="/createpdfdot" element={<DotPDF />} />
        <Route path="/uploaddata" element={<CSVImports />} />
        <Route path='*' element={ <NotFound /> }/>
      </Routes>
    </div>
  );
}

export default App;
