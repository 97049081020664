import { NavLink } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

const TotalQuote = () => {
  const [searchParams] = useSearchParams();
  const premium = searchParams.get('premium') || 0;
  const al = searchParams.get('al') || 0;
  const apd = searchParams.get('apd') || 0;
  const mtc = searchParams.get('mtc') || 0;
  const tgl = searchParams.get('tgl') || 0;
  const submissionNmber = searchParams.get('submissionNmber') || '';
  const coverwhalePage = `https://app.coverwhale.com/transportation/${submissionNmber}/0`;

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return(
    <div className="flex flex-col items-center">
      <h2 className="text-lg mt-4"><b>RAPID QUOTE</b></h2>
      <div className="bg-neutral-200 rounded w-2/3 md:w-1/2 px-10 py-8 my-8">
        <p className="text-2xl"><b>TOTAL ESTIMATED ANNUAL PREMIUM</b></p>
        <p className="text-green-700 text-5xl xl:text-9xl"><b>${numberWithCommas(premium)}</b></p>
      </div>
      <div className="w-1/2">
        <p className="text-2xl flex justify-between text-left border-b-2 border-black"><b>Auto Liability (AL)</b><b className="w-fit">${numberWithCommas(al)}</b></p>
        <p className="text-2xl flex justify-between text-left border-b-2 border-black"><b>Automobile Physical Damage (APD)</b><b className="w-fit">${numberWithCommas(apd)}</b></p>
        <p className="text-2xl flex justify-between text-left border-b-2 border-black"><b>Motor Truck Cargo (MTC)</b><b className="w-fit">${numberWithCommas(mtc)}</b></p>
        <p className="text-2xl flex justify-between text-left border-b-2 border-black"><b>Truckers General Liability (TGL)</b><b className="w-fit">${numberWithCommas(tgl)}</b></p>
      </div>
      <div className="flex flex-col items-center justify-evenly mt-28">
        <a
          className="bg-green-700 text-white w-1/2 rounded p-2"
          href={coverwhalePage}
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>CONTINUE TO QUOTE &rarr;</b>
        </a>
        <NavLink
          to={'/'}
        >
          <button type="button" className="bg-yellow-300 w-1/2 rounded p-2 mt-2 mb-28"><b>&larr; GO BACK TO INPUT SECTION</b></button>
        </NavLink>
      </div>
    </div>
  );
};

export default TotalQuote;