import { useLocation } from 'react-router-dom';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import CompanyPDF from '../components/CompanyPDF/CompanyPDF';
import { IconContext } from "react-icons";
import { HiDownload } from "react-icons/hi";

const SubmissionPDF = () => {
  const location = useLocation();
  const submissionData = location.state;
  const submissionNmber = submissionData.submission_number || '';
  const al = submissionData.coverages.al?.premium || 0;
  const apd = submissionData.coverages.apd?.premium || 0;
  const mtc = submissionData.coverages.mtc?.premium || 0;
  const ntl = submissionData.coverages.ntl?.premium || 0;
  const tgl = submissionData.coverages.tgl?.premium || 0;
  const premium = al + apd + mtc + ntl + tgl;
  const pdfData = {
    company: {
      companyName: "No company",
      companyAddres: "No address",
      companyCity: "No city"
    },
    insuranceData: {
      premium,
      al,
      apd,
      mtc,
      ntl,
      tgl,
      submissionNmber
    },
    producer: {
      AgentName: submissionData.retailAgent.FirstName + ' ' + submissionData.retailAgent.LastName,
      EmailAddress: submissionData.retailAgent.Email,
      WorkPhone: submissionData.retailAgent.Phone
    },
    startDate: submissionData.coverage.effectiveDate.slice(0, 10)
  };
  const companyName = pdfData.company.companyName ? pdfData.company.companyName : '';

  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = today.getMonth();
  const yyyy = today.getFullYear();
  const monthName = month[mm];
  const todayTitle = monthName + ' ' + dd + ', ' + yyyy;

  return (
    <div style={{position:"relative"}}>
      <div className="loader">
        <h1 className="generatePDFTitle">GENERATING PDF . . .</h1>
        <div className="spinner" />
      </div>
      <PDFViewer style={{width:"100%", height:"100vh", position:"absolute", left:"0", top:"0", marginTop: "-64px", zIndex: "101"}}>
        <CompanyPDF pdfData={pdfData} />
      </PDFViewer>
      <PDFDownloadLink
        className="hidden xl:block fixed w-fit mr-24 mt-2 right-0 top-0 z-[102] text-white bg-[rgb(50,54,57)]"
        document={<CompanyPDF pdfData={pdfData} />}
        fileName={`${companyName}-${todayTitle}-${submissionNmber}.pdf`}
      >
        {({ loading }) => (loading ? '' :
          <div className="p-2">
            <IconContext.Provider value={{ size: "1.5rem" }}>
              <HiDownload />
            </IconContext.Provider>
          </div>
        )}
      </PDFDownloadLink>
    </div>
  );
};

export default SubmissionPDF;
