import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Steps, useSteps } from "react-step-builder";
import { NumericFormat } from 'react-number-format';
import { getInsuranceLocalStorage, saveCompanyPDFLocalStorage } from "../../api/insuranceLocalStorage";
import { getTotalAxios, sendDotAxios } from "../../api/axiosFunctions";
import Circles from "./Circles";
import usStates from '../../data/us-states-with-quotes.json';
import producers from '../../data/AFI-ContactInfo-Producers.json';
import './MultiStepsForm.css';

const MultiStepsForm = () => {
  const navigate = useNavigate();
  const { prev, next, jump, current, total } = useSteps();
  const [loading, setLoading] = useState(false);
  const [insuranceForm, setInsuranceForm] = useState(getInsuranceLocalStorage());
  const [dotChecked, setDotChecked] = useState(false);
  const [company, setCompany] = useState({
    companyName:'No Company Name',
    companyAddres: "No Addres",
    companyCity: "No City",
    garageState: "No State",
    garageZip: "No ZIP"
  });
  const [producer, setProducer] = useState({
    AgentName: "producer",
    id: "0", 
    EmailAddress: "noemail@teamafi.com",
    WorkPhone: "000000000",
    URLImage: 'https://american-fleet.s3.amazonaws.com/headshots/megan.jpg'
  });
  let today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  today = yyyy + '-' + mm + '-' + dd;

  const handleChange = (event) => {
    let newValue = event.target.value;
    const inputName = event.target.name;
    if (inputName === 'avgValueTruck') {
      newValue = newValue.replace(/,/g,'');
      newValue = parseInt(newValue,10);
    };
    setInsuranceForm((prevState)=> {
      return({
        ...prevState,
        [inputName]: newValue,
      });
    });
  };

  const handleProducerChange= (event) => {
    const newValue = event.target.value;
    setProducer(producers.filter((producer) => producer.AgentName === newValue)[0] || {
      AgentName: "producer",
      id: "0", 
      EmailAddress: "noemail@teamafi.com",
      WorkPhone: "000000000",
      URLImage: 'https://american-fleet.s3.amazonaws.com/headshots/megan.jpg'
    });
  };

  const updateWithDotNumber = async (dotNumber) => {
    if (producer.id === "0") {
      alert("Select Producer");
      return;
    };
    if (today > insuranceForm.policyStartDate) {
      alert("Policy Start Day can't be in the past");
      return;
    }
    if (!dotChecked && dotNumber !== '0') {
      const response = await sendDotAxios(dotNumber);
      if (response) {
        setInsuranceForm((prevState)=> {
          return({
            ...prevState,
            "garageState": response.garageState,
            "garageZip": response.garageZip,
            "drivers": response.drivers,
            "trucks": response.trucks
          });
        });
        setCompany({
          companyName: response.legalName,
          companyAddres: response.address,
          companyCity: response.city,
          garageState: response.garageState,
          garageZip: response.garageZip
        })
        jump(2);
      } else {
        alert('Invalid DOT Number')
      }
    } else {
      setCompany({
        companyName:'No Company Name',
        companyAddres: "No Addres",
        companyCity: "No City",
        garageState: "No State",
        garageZip: "No ZIP"
      })
      jump(2);
    };
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    const startDate = insuranceForm.policyStartDate;
    const trucks = insuranceForm.trucks !== 0 ? insuranceForm.trucks  : 1;
    const drivers = insuranceForm.drivers !== 0 ? insuranceForm.drivers  : 1;
    setLoading(true);
    const insuranceData = await getTotalAxios(insuranceForm);
    setLoading(false);
    if (insuranceData.premium) {
      saveCompanyPDFLocalStorage({insuranceData, producer, company, trucks, startDate, drivers});
      navigate('/insurance');
    } else {
      alert("oops, something went wrong!");
    };
  };

  const restartForm = () => {
    setInsuranceForm({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dotNumber: '0',
      businessYears: 5,
      garageState: 'AL',
      garageZip: '',
      drivers: 1,
      trucks: 1,
      avgValueTruck: 40000,
      trailers: 0,
      autoLiability: '750000',
      autoLiabilityStatus: 'Y',
      personalInjuryProtection: 'Y',
      uninsured: 'Y',
      physicalDamage: 'Y',
      motorTruckCargo: 'N',
      nonTruckingLiability: 'Y',
      policyStartDate: today
    });
    jump(1);
  };

  return (
    <div className="multiStepsFormPage">
      <div className="circlesDiv">
        <Circles circleNumber={total} current={current} />
      </div>
      <form onSubmit={handleSubmit} className="multiStepsForm">
        <Steps>
          <div>
            <label>DOT Number
              <div className="flex items-center pl-8">
                <input
                  type="checkbox" 
                  className="h-4 w-4 mr-4 my-2 accent-red-600"
                  checked={dotChecked}
                  onChange={() =>setDotChecked(!dotChecked)}
                />
                  I do not have a DOT number yet
              </div>
              { !dotChecked && 
                <input
                  name="dotNumber"
                  type="number"
                  className="stepsinput"
                  id="dotNumberInput"
                  onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                  onChange={handleChange}
                  value={insuranceForm.dotNumber}
                />
              }
            </label>
            <label>Producer
              <select name="producer" className="stepsselect" id="producer" value={producer.AgentName} onChange={handleProducerChange}>
                <option value="producer">Select Producer</option>
                {producers.map((producer) => (
                  <option key={producer.id} value={producer.AgentName}>{producer.AgentName}</option>
                ))}
              </select>
            </label>
            <label>Business Type
              <select name="opsTruck" className="stepsselect" onChange={handleChange} value={insuranceForm.opsTruck}>
                <option value="opsLocal">Local Trucker</option>
                <option value="opsLongHaul">Long Haul Trucker</option>
                <option value="opsHotShot">Hot Shot</option>
                <option value="opsDumpTruckSandGravel">Dump Truck (Sand and Gravel)</option>
                <option value="opsTowing">Towing</option>
                <option value="opsOther">Other</option>
              </select>
            </label>
            <label>Policy Start Date
              <input name="policyStartDate" type="date" min={today} className="stepsinput" onChange={handleChange} value={insuranceForm.policyStartDate} />
            </label>
          </div>
          <div>
            <label>Owner First Name
              <input name="firstName" type="text" className="stepsinput" onChange={handleChange} value={insuranceForm.firstName} />
            </label>
            <label>Owner Last Name
              <input name="lastName" type="text" className="stepsinput" onChange={handleChange} value={insuranceForm.lastName} />
            </label>
            <label>Owner Email (i.e. somebody@mail.com)
              <input name="email" type="email" className="stepsinput" onChange={handleChange} value={insuranceForm.email} />
            </label>
            <label>Owner Phone (i.e. 8174700565)
              <input name="phone" type="text" className="stepsinput" onChange={handleChange} value={insuranceForm.phone} />
            </label>
          </div>
          <div>
            <label>Years In Business 
              <input name="businessYears" type="number" className="stepsinput" onChange={handleChange} value={insuranceForm.businessYears} />
            </label>
            <label>Garage State
            <select name="garageState" id="garageState" className="stepsselect" onChange={handleChange} value={insuranceForm.garageState}>
              {usStates.map((state) => (
                <option key={state.id} value={state.value_back}>{state.value}</option>
              ))}
            </select>
            </label>
            <label>Garage Zip
              <input name="garageZip" type="text" className="stepsinput" onChange={handleChange} value={insuranceForm.garageZip} />
            </label>
          </div>
          <div className="mb-32">
            <label>Number of Drivers
              <input name="drivers" type="number" className="stepsinput" onChange={handleChange} value={insuranceForm.drivers} />
            </label>
            <label>Number of Trucks
              <input name="trucks" type="number" className="stepsinput" onChange={handleChange} value={insuranceForm.trucks} />
            </label>
            <label>Avg. Value Per Truck
              <span className="currencyinput flex items-center">$<NumericFormat value={insuranceForm.avgValueTruck} thousandSeparator="," name="avgValueTruck" id="spanstepsinput" onChange={handleChange} /></span>
            </label>
            <label className="mt-2">Number of Trailers
              <input name="trailers" type="number" className="stepsinput" onChange={handleChange} value={insuranceForm.trailers} />
            </label>
            <div className="absolute left-0 mt-4 w-screen flex justify-center">
              <fieldset className="w-1/2 space-y-4 grid grid-cols-4 gap-x-2 items-center justify-center">
                <h4 className="text-lg font-bold col-span-4">Radius of driving</h4>
                <legend className="sr-only">Radius of driving</legend>
                <div className="relative flex items-center justify-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="0miles"
                      aria-describedby="0-50 miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "0"}
                      onChange={handleChange}
                      value="0"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-1 text-lg text-left w-fit">
                    <label htmlFor="0-50 miles" className="font-medium text-gray-700">
                      0-50 Miles
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="51miles"
                      aria-describedby="51 - 200 Miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "51"}
                      onChange={handleChange}
                      value="51"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-1 text-lg text-left w-fit">
                    <label htmlFor="51 - 200 Miles" className="font-medium text-gray-700">
                      51-200 Miles
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="201miles"
                      aria-describedby="201 - 500 Miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "201"}
                      onChange={handleChange}
                      value="201"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-1 text-lg text-left w-fit">
                    <label htmlFor="201 - 500 Miles" className="font-medium text-gray-700">
                      201-500 Miles
                    </label>
                  </div>
                </div>
                <div className="relative flex items-center">
                  <div className="flex h-5 items-center w-1/6">
                    <input
                      id="501miles"
                      aria-describedby="01+ Miles"
                      name="radiusDriving"
                      type="checkbox"
                      checked={insuranceForm.radiusDriving === "501"}
                      onChange={handleChange}
                      value="501"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                    />
                  </div>
                  <div className="ml-1 text-lg text-center w-fit">
                    <label htmlFor="501+ Miles" className="font-medium text-gray-700">
                      501+ Miles
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div>
            <label>Auto Liability (AL)
              <div className="flex items-center">
                <input
                  type="radio"
                  value="Y"
                  name="autoLiabilityStatus"
                  className="w-4 mr-4"
                  checked={insuranceForm.autoLiabilityStatus === 'Y'}
                  onChange={handleChange}
                />
                Accept
                <input
                  type="radio"
                  value="N"
                  name="autoLiabilityStatus"
                  className="w-4 ml-12 mr-4"
                  checked={insuranceForm.autoLiabilityStatus === 'N'}
                  onChange={handleChange}
                />
                Reject
              </div>
              {insuranceForm.autoLiabilityStatus !== "N" &&
                <select className="stepsselect mt-2" name="autoLiability" id="autoLiability" onChange={handleChange} value={insuranceForm.autoLiability}>
                  <option value="750000">$750,000</option>
                  <option value="1000000">$1,000,000</option>
                </select>
              }
            </label>
            <label>Non-Trucking Liability (NTL)
              <div className="flex items-center">
                <input
                  type="radio"
                  value="Y"
                  name="nonTruckingLiability"
                  className="w-4 mr-4"
                  checked={insuranceForm.nonTruckingLiability === "Y"}
                  onChange={handleChange}
                />
                Accept
                <input
                  type="radio"
                  value="N"
                  name="nonTruckingLiability"
                  className="w-4 ml-12 mr-4"
                  checked={insuranceForm.nonTruckingLiability === "N"}
                  onChange={handleChange}
                />
                Reject
              </div>
            </label>
            <label>Truckers General Liability (TGL)
              <div className="flex items-center">
                <input
                  type="radio"
                  value="Y"
                  name="generalLiability"
                  className="w-4 mr-4"
                  checked={insuranceForm.generalLiability === "Y"}
                  onChange={handleChange}
                />
                Accept
                <input
                  type="radio"
                  value="N"
                  name="generalLiability"
                  className="w-4 ml-12 mr-4"
                  checked={insuranceForm.generalLiability === "N"}
                  onChange={handleChange}
                />
                Reject
              </div>
            </label>
            <label>Personal Injury Protection (PIP)
              <div className="flex items-center">
                <input
                  type="radio"
                  value="Y"
                  name="personalInjuryProtection"
                  className="w-4 mr-4"
                  checked={insuranceForm.personalInjuryProtection === "Y"}
                  onChange={handleChange}
                />
                Accept
                <input
                  type="radio"
                  value="N"
                  name="personalInjuryProtection"
                  className="w-4 ml-12 mr-4"
                  checked={insuranceForm.personalInjuryProtection === "N"}
                  onChange={handleChange}
                />
                Reject
              </div>
            </label>
            <label>Uninsured / Underinsured
              <div className="flex items-center">
                <input
                  type="radio"
                  value="Y"
                  name="uninsured"
                  className="w-4 mr-4"
                  checked={insuranceForm.uninsured === "Y"}
                  onChange={handleChange}
                />
                Accept
                <input
                  type="radio"
                  value="N"
                  name="uninsured"
                  className="w-4 ml-12 mr-4"
                  checked={insuranceForm.uninsured === "N"}
                  onChange={handleChange}
                />
                Reject
              </div>
            </label>
            <label>Physical Damage (PD)
              <div className="flex items-center">
                <input
                  type="radio"
                  value="Y"
                  name="physicalDamage"
                  className="w-4 mr-4"
                  checked={insuranceForm.physicalDamage === "Y"}
                  onChange={handleChange}
                />
                Accept
                <input
                  type="radio"
                  value="N"
                  name="physicalDamage"
                  className="w-4 ml-12 mr-4"
                  checked={insuranceForm.physicalDamage === "N"}
                  onChange={handleChange}
                />
                Reject
              </div>
            </label>
            <label>Motor Truck Cargo (MTC)
              <select name="motorTruckCargo" id="motorTruckCargo" className="stepsselect mt-2" onChange={handleChange} value={insuranceForm.motorTruckCargo}>
                <option value="N">No, Excluded</option>
                <option value="100000">$100,000</option>
                <option value="125000">$125,000</option>
                <option value="150000">$150,000</option>
                <option value="175000">$175,000</option>
                <option value="200000">$200,000</option>
                <option value="250000">$250,000</option>
              </select>
            </label>
          </div>
        </Steps>
        <div className="multiStepsButtonDiv">
          <button type="button" onClick={() => restartForm()} className="multiStepsResetButton">RESET FORM</button>
          <div className="multiStepsNextButtonDiv">
            {current > 1 && <button type="button" className="multiStepsButtonPrevious" onClick={prev}>PREVIOUS</button>}
            {current < 5 && <button type="button" className="multiStepsButtonNext" onClick={current === 1 ? () => updateWithDotNumber(insuranceForm.dotNumber) : next}>NEXT</button>}
            {current === 5 && <button type="submit" className="multiStepsButtonNext">GENERATE QUOTE</button>}
          </div>
        </div>
      </form>
      
      { loading && (
        <div className="loader">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

export default MultiStepsForm;
