import { useState } from "react";

const QuoteIndication = () => {
  const [modalDriver,setModalDriver] = useState('modal_inactive');
  const [modalVehicle,setModalVehicle] = useState('modal_inactive');
  const [modalTrailer,setModalTrailer] = useState('modal_inactive');

  return(
    <div className="flex flex-col items-center">
      <h2 className="text-lg mt-4"><b>COMPANY NAME QUOTE</b></h2>
      <div className="w-11/12">
        <div className="grid gap-4 my-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <label>Owner First Name
            <input name="firstName" type="text" disabled />
          </label>
          <label>Owner Last Name
            <input name="lastName" type="text" disabled />
          </label>
          <label>Owner Email
            <input name="email" type="email" disabled />
          </label>
          <label>Owner phone
            <input name="phone" type="text" disabled  />
          </label>
          <label>DOT Number
            <input name="dotNumber" type="number" disabled />
          </label>
          <label>Legal Company Name
            <input name="legalCompanyName" type="text" disabled />
          </label>
          <label>DBA
            <input name="dba" type="text" disabled />
          </label>
          <label>Radius
            <input name="radius" type="number" disabled />
          </label>
        </div>
      </div>
      <span className="border-t-2 border-zinc-100 w-11/12" />
      <div className="grid gap-6 w-11/12 my-8 md:grid-cols-1 lg:grid-cols-3">
        <div className="grid gap-4 md:grid-cols-2">
          <label className="md:col-span-2">Garage Address Street
            <input name="garageStreet" type="text" disabled />
          </label>
          <label>Garage City
            <input name="garageCity" type="text" disabled />
          </label>
          <label>Garage State
            <input name="garageState" type="text" disabled />
          </label>
          <label className="md:col-span-2">Garage Address Street Line 2
            <input name="garageStreet2" type="text" disabled />
          </label>
          <label>Garage Zip
            <input name="garageZip" type="text" disabled />
          </label>
          <label>Garage Country
            <input name="garageCountry" type="text" disabled />
          </label>
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <label className="md:col-span-2">Mailing Address Street
            <input name="mailingStreet" type="text" disabled />
          </label>
          <label>Mailing City
            <input name="mailingCity" type="text" disabled />
          </label>
          <label>Mailing State
            <input name="mailingState" type="text" disabled />
          </label>
          <label className="md:col-span-2">Mailing Address Street Line 2
            <input name="mailingStreet2" type="text" disabled />
          </label>
          <label>Mailing Zip
            <input name="mailingZip" type="text" disabled />
          </label>
          <label>Mailing Country
            <input name="mailingCountry" type="text" disabled />
          </label>
        </div>
        <div className="grid gap-4 md:grid-cols-2">
          <label className="md:col-span-2">Billing Address Street
            <input name="billingStreet" type="text" disabled />
          </label>
          <label>Billing City
            <input name="billingCity" type="text" disabled />
          </label>
          <label>Billing State
            <input name="billingState" type="text" disabled />
          </label>
          <label className="md:col-span-2">Billing Address Street Line 2
            <input name="billingStreet2" type="text" disabled />
          </label>
          <label>Billing Zip
            <input name="billingZip" type="text" disabled />
          </label>
          <label>Billing Country
            <input name="billingCountry" type="text" disabled />
          </label>
        </div>
      </div>
      <span className="border-t-2 border-zinc-100 w-11/12" />
      <div className="w-11/12 my-16 flex flex-col">
        <div className="flex justify-between gap-x-8">
          <h3 className="bg-blue-900 text-white text-left rounded flex items-center p-4 w-5/6">Drivers</h3>
          <button type="button" onClick={() => setModalDriver('modal_active')} className="w-1/3 md:w-1/6 xl:w-1/12 bg-green-700 text-white rounded p-4">Add Driver</button>
        </div>
        <div className="my-4">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>License Number</th>
                <th>License State</th>
                <th>Date of Birth</th>
                <th>Date of Hire</th>
                <th>Years of Experience</th>
                <th>Eligibility</th>
                <th>Total Accidents</th>
                <th>Total Violations</th>
                <th>Total Suspensions</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-11/12 my-16 flex flex-col">
        <div className="flex justify-between gap-x-8">
          <h3 className="bg-blue-900 text-white text-left rounded flex items-center p-4 w-5/6">Vehicles</h3>
          <button type="button" onClick={() => setModalVehicle('modal_active')} className="w-1/3 md:w-1/6 xl:w-1/12 bg-green-700 text-white rounded p-4">Add Vehicle</button>
        </div>
        <div className="my-4">
          <table>
            <thead>
              <tr>
                <th>VIN</th>
                <th>Year</th>
                <th>Make</th>
                <th>Model</th>
                <th>Value</th>
                <th>Class Key</th>
                <th>Body Type Key</th>
                <th>Deductible Type</th>
                <th>Deductible</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>
      </div>
      <div className="w-11/12 my-16 flex flex-col">
        <div className="flex justify-between gap-x-8">
          <h3 className="bg-blue-900 text-white text-left rounded flex items-center p-4 w-5/6">Trailers</h3>
          <button type="button" onClick={() => setModalTrailer('modal_active')} className="w-1/3 md:w-1/6 xl:w-1/12 bg-green-700 text-white rounded p-4">Add Trailer</button>
        </div>
        <div className="my-4">
          <table>
            <thead>
              <tr>
                <th>VIN</th>
                <th>Year</th>
                <th>Make</th>
                <th>Model</th>
                <th>Value</th>
                <th>Body Type Key</th>
                <th>Deductible Type</th>
                <th>Deductible</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div className={modalDriver}>
        <div className="flex flex-col items-center">
          <form className="w-1/3 pb-5 bg-white rounded">
            <div className="bg-blue-800 text-white rounded flex justify-between px-4 py-1">
              <p className="w-fit">+ Add Driver</p>
              <button className="w-fit" onClick={() => setModalDriver('modal_inactive')} type="button">X</button>
            </div>
            <div className="grid grid-cols-2 gap-4 px-10 mt-4">
              <label>First Name
                <input name="firstName" type="text" />
              </label>
              <label className="flex flex-col items-start">Last Name
                <input name="lastName" type="text" />
              </label>
              <label className="flex flex-col items-start">License Number
                <input name="licenseNumber" type="number" />
              </label>
              <label className="flex flex-col items-start">License State
                <input name="licenseState" type="text"  />
              </label>
              <label className="flex flex-col items-start">Date of Birth
                <input name="dateBirth" type="date" />
              </label>
              <label className="flex flex-col items-start">Date of Hire
                <input name="dateHire" type="date" />
              </label>
              <label className="flex flex-col items-start">Years of Experience
                <input name="yearsExperience" type="number" />
              </label>
              <label className="flex flex-col items-start">Elegibility
                <input name="elegibility" type="text" />
              </label>
              <label className="flex flex-col items-start">Accidents Total
                <input name="accidentsTotal" type="number" />
              </label>
              <label className="flex flex-col items-start">Violations Total
                <input name="violationsTotal" type="number" />
              </label>
              <label className="flex flex-col items-start">Suspensions Total
                <input name="suspensionsTotal" type="number" />
              </label>
              <button type="button" className="bg-green-700 text-white rounded mt-4 p-2 col-span-2"><b>+ Add Driver</b></button>
            </div>
          </form>
        </div>
      </div>
      <div className={modalVehicle}>
        <div className="flex flex-col items-center">
          <form className="w-1/3 pb-5 bg-white rounded">
            <div className="bg-blue-800 text-white rounded flex justify-between px-4 py-1">
              <p className="w-fit">+ Add Vehicle</p>
              <button className="w-fit" onClick={() => setModalVehicle('modal_inactive')} type="button">X</button>
            </div>
            <div className="grid grid-cols-2 gap-4 px-10 mt-4">
              <label className="flex flex-col items-start">VIN
                <input name="vehiclevin" type="text" />
              </label>
              <label className="flex flex-col items-start">Year
                <input name="vehicleyear" type="text" />
              </label>
              <label className="flex flex-col items-start">Make
                <input name="vehiclemake" type="text" />
              </label>
              <label className="flex flex-col items-start">Model
                <input name="vehiclemodel" type="text"  />
              </label>
              <label className="flex flex-col items-start">Value
                <input name="vehiclevalue" type="number" />
              </label>
              <label className="flex flex-col items-start">Class Key
                <input name="vehicleclassKey" type="text" />
              </label>
              <label className="flex flex-col items-start">Body Type Key
                <input name="vehiclebodyTypeKey" type="text" />
              </label>
              <label className="flex flex-col items-start">Deductible Type
                <input name="vehicledeductibleType" type="text" />
              </label>
              <label className="flex flex-col items-start">Deductible
                <input name="vehicledeductible" type="text" />
              </label>
              <button type="button" className="bg-green-700 text-white rounded mt-4 p-2 col-span-2"><b>+ Add Vehicle</b></button>
            </div>
          </form>
        </div>
      </div>
      <div className={modalTrailer}>
        <div className="flex flex-col items-center">
          <form className="w-1/3 pb-5 bg-white rounded">
            <div className="bg-blue-800 text-white rounded flex justify-between px-4 py-1">
              <p className="w-fit">+ Add Trailer</p>
              <button className="w-fit" onClick={() => setModalTrailer('modal_inactive')} type="button">X</button>
            </div>
            <div className="grid grid-cols-2 gap-4 px-10 mt-4">
              <label className="flex flex-col items-start">VIN
                <input name="trailervin" type="text" />
              </label>
              <label className="flex flex-col items-start">Year
                <input name="traileryear" type="text" />
              </label>
              <label className="flex flex-col items-start">Make
                <input name="trailermake" type="text" />
              </label>
              <label className="flex flex-col items-start">Model
                <input name="trailermodel" type="text"  />
              </label>
              <label className="flex flex-col items-start">Value
                <input name="trailervalue" type="number" />
              </label>
              <label className="flex flex-col items-start">Body Type Key
                <input name="trailerbodyTypeKey" type="text" />
              </label>
              <label className="flex flex-col items-start">Deductible Type
                <input name="trailerdeductibleType" type="text" />
              </label>
              <label className="flex flex-col items-start">Deductible
                <input name="trailerdeductible" type="text" />
              </label>
              <button type="button" className="bg-green-700 text-white rounded mt-4 p-2 col-span-2"><b>+ Add Trailer</b></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default QuoteIndication;
